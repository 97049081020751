import { Container, Row, Col } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import colorSharp from "../assets/img/color-sharp.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import { skills } from '../data';
import BadgeCheckIcon from '../assets/img/badgecheckicon.svg'

export const Skills = () => {

  return (
    <section className="skill" id="skills">
      <img className="background-image-left" src={colorSharp} />
      <Container>
        <Row>
          <Col>
            <div className="skill-bx">
              <h2>Skills &amp; Technologies</h2>
              <div className="skills-box">
                {skills.map((skill) => (
                  <div key={skill} className="p-2 sm:w-1/2 w-full skill-rows">
                    <div className="bg-gray-800 rounded flex p-4 h-full items-center skill-a">
                    <img src={BadgeCheckIcon} className="imgbadge" />
                      <span className="title-font font-medium text-white skills-text">
                        {skill}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <img className="background-image-left" src={colorSharp2} />
    </section>
  );
};
