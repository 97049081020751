import { Container, Col, Row } from 'react-bootstrap';
import { MailchimpForm } from './MailchimpForm';
import logo from '../assets/img/logo.svg';
import navIcon1 from '../assets/img/nav-icon1.svg';
import github from '../assets/img/github.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
export const Footer = () => {

  return (
    <footer className="footer">
      <Container>
        <Row className="align-item-center">
          <Col sm={6} >
            <h1 className='name-logo' title='Drutu Mihai-Andrei'>D.M.A.</h1>
          </Col>
          <Col sm={6} className='text-center text-sm-end'>
            <div className='social-icon'>
              <a href='https://www.linkedin.com/in/dru%C8%9Bu-mihai-andrei-7b906a270/' target="_blank"><img src={navIcon1} /></a>
              <a href='https://github.com/DrutuMihai' target="_blank"><img src={github} /></a>
            </div>
            <p>CopyRight @ 2023. All Right Reserved. Drutu Mihai-Andrei</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}