export const skills = [
  "React",
  "JavaScript",
  "NodeJs",
  "ExpressJs",
  "SASS",
  "CSS",
  "HTML5",
  "Git",
];
